const gradientColors = [
  '#47A32B',
  '#A32B4B',
  '#2B62A3',
  '#FDFBBC',

  '#7C85A5', // repeat from here

  '#47A32B',
  '#A32B4B',
  '#2B62A3',
  '#FDFBBC',

  '#7C85A5',

  '#47A32B',
  '#A32B4B',
  '#2B62A3',
  '#FDFBBC',
];

module.exports = gradientColors;
